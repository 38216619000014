import { handleActions } from "redux-actions";
import { filter, findIndex, has, includes } from "lodash";
import {
  fetchOrganizationPlanItemsStart,
  fetchOrganizationPlanItemsSuccess,
  fetchOrganizationPlanItemsFailure,
  logoutStart,
  manuallyAddOrganizationPlanItems,
  manuallyRemoveOrganizationPlanItems,
  manuallyReplaceOrganizationPlanItems,
  manuallyClearOrganizationPlanItems,
} from "@/store/actions";

const initialState = {
  data: {},
};

// - Endpoint for data is `/api/v1/organizations/{ID}/plans/current/plan-items`
// - For saga-based actions: Do not filter the fetch request. All items must be
//   included when loading data from API for saga actions here. (use manual actions)

export default handleActions(
  {
    // Inject items into redux. (does not modify server data)
    // ------------------------
    // - `payload` should contain the following properties:
    //    - organization_id:    Id of target org
    //    - items:              Array of plan item objects.
    [manuallyAddOrganizationPlanItems]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      initializeOrgEntry(_state, payload.organization_id);

      // Working draft of plan items.
      let draftItems = [..._state.data[payload.organization_id].items];

      // Make the mods.
      for (let i = 0; i < payload.items.length; i++) {
        // Format item for storage
        let fpi = formatPlanItem(payload.items[i]);

        // If current item not present, add it. If present, replace it.
        let itemIndex = findIndex(draftItems, ["id", fpi.id]);

        if (-1 === itemIndex) {
          // not present, add it
          draftItems.push(fpi);
        } else {
          // present, replace it
          draftItems[itemIndex] = fpi;
        }
      }

      _state.data[payload.organization_id] = {
        loading: false,
        failed: false,
        items: draftItems,
      };

      return _state;
    },

    // Remove items from redux. (does not modify server data)
    // ------------------------
    // - `payload` should contain the following properties:
    //    - organization_id:    Id of target org
    //    - items:              Array of plan item objects.
    [manuallyRemoveOrganizationPlanItems]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      initializeOrgEntry(_state, payload.organization_id);

      // Working draft of plan items.
      let draftItems = [..._state.data[payload.organization_id].items];

      // Remove draftItems specfied in payload.items.
      let idsToRemove = [];
      for (let i = 0; i < payload.items.length; i++) {
        idsToRemove.push(parseInt(payload.items[i].id, 10));
      }
      draftItems = filter(draftItems, (item) => {
        return !includes(idsToRemove, item.id);
      });

      _state.data[payload.organization_id] = {
        loading: false,
        failed: false,
        items: draftItems,
      };
      return _state;
    },

    // Replaces one or more of an org's plan items in redux. (does not modify server data)
    // ------------------------------------------
    // - `payload` should contain the following properties:
    //    - organization_id:    Id of target org
    //    - items:              Array of plan item objects.
    [manuallyReplaceOrganizationPlanItems]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      initializeOrgEntry(_state, payload.organization_id);

      // Working draft of plan items.
      let draftItems = [..._state.data[payload.organization_id].items];

      // Replace draftItems specfied in payload.items.
      for (let i = 0; i < draftItems.length; i++) {
        let thisId = parseInt(draftItems[i].id);
        for (let i2 = 0; i2 < payload.items.length; i2++) {
          // If current payload item has same id, replace the
          // draft item with it.
          if (thisId === parseInt(payload.items[i2].id, 10)) {
            draftItems[i] = payload.items[i2];
          }
        }
      }

      _state.data[payload.organization_id] = {
        loading: false,
        failed: false,
        items: draftItems,
      };

      return _state;
    },

    // Removes all of org's plan items in redux. (does not modify server data)
    // -----------------------------------------
    // - no payload needed.
    [manuallyClearOrganizationPlanItems]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      initializeOrgEntry(_state, payload.organization_id);

      _state.data[payload.organization_id] = {
        loading: false,
        failed: false,
        items: [],
      };
    },

    // ------------------
    // Saga-based actions
    // ------------------
    [fetchOrganizationPlanItemsStart]: (state, { payload }) => {
      let _state = { ...state };

      // Add/increment loading flag (top-level, not org-specific).
      if (!has(_state, "loading")) {
        _state.loading = 1;
      } else {
        _state.loading++;
      }

      // Add org entry if not yet present.
      initializeOrgEntry(_state, payload.organization_id, true);

      // Set-up the org specific data
      _state.data[payload.organization_id] = {
        ..._state.data[payload.organization_id],
        loading: true,
        failed: false,
        // omit items from here in case it had values
      };

      return _state;
    },
    [fetchOrganizationPlanItemsSuccess]: (state, { payload }) => {
      if (!payload?.data || !payload?.meta) {
        console.error("Missing a payload property in fetchOrganizationPlanItemsSuccess.", payload);
        return { ...state };
      }
      // -----------------
      // @TODO
      // We need the org ID but don't currently have a reliable, structured
      // place to get it. For now, extract it from the meta.path string.
      // We'll eventually add `organization_id` as a meta property.
      let path = payload.meta.path;
      let matches = path.match(/\/api\/v1\/organizations\/(\d+)\/plans\/current\/plan-items/);
      let orgId = Number(matches[1]);
      // -----------------

      let planItems = [...payload.data];

      // strip out heavy properties from each.
      for (let i = 0; i < planItems.length; i++) {
        planItems[i] = formatPlanItem(planItems[i]);
      }

      return {
        ...state,
        loading: state.loading - 1,
        data: {
          ...state.data,
          [orgId]: {
            loading: false,
            failed: false,
            items: planItems,
          },
        },
      };
    },
    [fetchOrganizationPlanItemsFailure]: (state, { payload }) => {
      // Not a lot we can do for a failed request, so log it to the
      // console with some debugging.
      console.error("fetchOrganizationPlanItemsFailure", state, payload);

      // Just return a mostly unmodified copy of state.
      return {
        ...state,
        loading: state.loading - 1,
      };
    },
    [logoutStart]: (state) => ({
      ...initialState,
    }),
  },
  initialState
);

/**
 * Modifies state to include an entry for specified org.
 *
 * @param {object} stateRef state ref that will be modified in place
 * @param {number} orgId
 * @param {boolean} loading
 */
const initializeOrgEntry = (stateRef, orgId, loading = false) => {
  if (!has(stateRef.data, orgId)) {
    stateRef.data[Number(orgId)] = {
      loading: false,
      failed: false,
      items: [],
    };
  }
};

/**
 * Modifies plan item to omit properties we don't want in reux.
 *
 * @param {object} planItemRef plan item to modify
 * @returns {object}
 */
const formatPlanItem = (planItem) => {
  var p = { ...planItem };
  // FYI, delete is safe even if the target property is missing.
  delete p.items;
  delete p.response;
  return p;
};
