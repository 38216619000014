import { handleActions } from "redux-actions";
import { has, get, isNil } from "lodash";
import {
  fetchOrganizationSetsStart,
  fetchOrganizationSetsSuccess,
  fetchOrganizationSetsFailure,
  logoutStart,
} from "@/store/actions";
import isNumeric from "@/common/lib/isNumeric";

// Top level:
// {
//    loading: 0, // qty of active requests (0 when none)
//    data: {}    // data object as described below.
// }
//
// The data object will be keyed by org ID, with each entry being an object
// of assessments (or nulls) keyed by set id. Ex:
//
// {
//   10: {                    // (org #10)
//     20: {                  // (assessment #20)
//       loading: true,
//       failed: false,
//       set: { id: 20, name: "Healthy eating assessment", ... }
//     },
//     482: {                  // (assessment #482)
//       loading: 0,
//       failed: false,
//       set: { id: 482, name: "Healthy activities assessment", ... }
//     },
//   }
// }
//
// Note: Requests that specify a `set_id` are tracked as
// `data[organizationId][setId].failed` and
// `data[organizationId][setId].loading`.
//
// If a set_id is logged as `failed`, that flag is removed
// at the start of the next request for it. The flag will be set
// back to true if the request fails.
//
// Bulk requests are _not_ tracked in those arrays. However, the
// results of bulk requests _will_ reset the failed and loading
// flags to false for criteria that had a response object successfully
// received.
//
const initialState = {
  data: {},
};

export default handleActions(
  {
    [fetchOrganizationSetsStart]: (state, { payload }) => {
      let _state = { ...state };

      // Add org entry if not yet present.
      if (!has(_state.data, payload.organization_id)) {
        _state.data[Number(payload.organization_id)] = {};
      }

      // Add/increment loading flag (top-level, not org-specific).
      if (!has(_state, "loading")) {
        _state.loading = 1;
      } else {
        _state.loading++;
      }

      // If request was for a specific set, create or populate the
      // necessary properties to reflect that.
      if (has(payload, "set_id") && isNumeric(payload.set_id)) {
        // Request is for a single set, so check if we've already
        // got an entry for the org/set combo we can set flags on.
        _state.data[payload.organization_id] = {
          ..._state.data[payload.organization_id],
          [payload.set_id]: {
            loading: true,
            failed: false,
            set: null,
          },
        };
      }
      return _state;
    },
    [fetchOrganizationSetsSuccess]: (state, { payload }) => {
      if (!payload?.data || !payload?.meta) {
        console.error("Missing payload property in fetchOrganizationSetsSuccess.", payload);
        return { ...state };
      }

      let orgId = payload?.meta?.organization_id;
      let newOrgProps = {};

      // -- Handle payloads with at least one set.
      if (payload.data.length > 0) {
        for (let i = 0; i < payload.data.length; i++) {
          newOrgProps[payload.data[i].id] = {
            failed: false,
            loading: false,
            set: formatSetObj(payload.data[i]),
          };
        }
      }

      // @TODO Would be good if we could remove a set from
      // redux that comes back as having been removed from server.

      let oldOrgProps = {};
      if (!isNil(orgId)) {
        oldOrgProps = get(state.data, orgId, null);
      }

      return {
        ...state,
        loading: state.loading - 1,
        data: {
          ...state.data,
          [orgId]: {
            ...oldOrgProps,
            ...newOrgProps,
          },
        },
      };
    },
    [fetchOrganizationSetsFailure]: (state, { payload }) => {
      // Not a lot we can do for a failed request, so log it to the
      // console with some debugging.
      console.error("fetchOrganizationSetsFailure", state, payload);

      // Just return a mostly unmodified copy of state.
      return {
        ...state,
        loading: state.loading - 1,
      };
    },
    [logoutStart]: (state) => ({
      ...initialState,
    }),
  },
  initialState
);

/**
 * Removes heavy properties from set objects.
 *
 * We don't want/need them in redux and this helps prevent
 * incorrect usage of these objects.
 *
 * @param {object} s Set object.
 * @returns {object} Returns modified set object.
 */
const formatSetObj = (s) => {
  delete s.responses;
  return s;
};
